import { useAccessToken } from "~/composables/useAccessToken";
import { useUserStore } from "~/stores/user";
import maybeBypassAuthMiddlewareRoutes from "~/utils/maybeBypassAuthMiddlewareRoutes";

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (process.client) {
    const { getAccessToken } = useAccessToken();
    const token = await getAccessToken();


    if (!token && maybeBypassAuthMiddlewareRoutes(to)) {
      return; // Bypass the middleware
    }

    const userStore= useUserStore();
    if (userStore.getAccount.id !== null) {
      return;
    }

    try {
      const { $api } = useNuxtApp();
      let params = {};

      // Check if user is accessing delegate account
      if (sessionStorage.getItem('delegate_access_account')) {
        params = {
          accessing_account_id: sessionStorage.getItem('delegate_access_account'),
        }
      }

      const response= await $api.get('/me', {
        params: params,
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (response.data) {
        userStore.profile = {
          name: response.data.name,
          email: response.data.email,
          email_verified_at: response.data.email_verified_at,
          is_delegate_mode: response.data.is_delegate_mode ?? false
        };

        if (response.data?.account) {
          userStore.account = response.data?.account;
        }
        userStore.timeline = response.data?.timeline;
      }

    } catch (error) {
      console.error('Error fetching user:', error);
      throw error; // Rethrow the error to be caught by the caller
    }
  }
});
